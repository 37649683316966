<template>
  <div :class="'chart-' + chartId" class="chart-app">
    <div class="current-rank" v-show="disabledIndex !== activeIndex" :style="{ left: activePercentage + '%' }"><span>{{ $t('Jouw positie') }}</span></div>
    <chart ref="chart" @update="setPercentage" :ranks="ranks" :chart-id="chartId" :chart-data="data"></chart>
    <div class="legend"></div>
  </div>
</template>

<script>
import { mixins, HorizontalBar } from 'vue-chartjs'

const { reactiveProp } = mixins

// function to update the chart when clicking the legend items
function updateDataset (chart, index) {
  var meta = chart.getDatasetMeta(index)
  meta.hidden = meta.hidden === null ? !chart.data.datasets[index].hidden : null
  chart.update()
}

const Chart = {
  extends: HorizontalBar,
  mixins: [reactiveProp],
  props: ['ranks', 'chartData', 'chartId'],
  data: function () {
    return {
      rank: 0,
      responsive: true,
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        legend: {
          display: false
        },
        legendCallback: (chart) => {
          // create legend html
          // let wrapper = '.benchmark-chart';
          let legendItems = ''
          const chartAppId = this.chartId
          const wrapper = '.chart-' + chartAppId
          const totalStars = 4
          const ratingMap = {
            A: 4,
            B: 3,
            C: 2,
            D: 1
          }

          for (let i = 0; i < chart.data.datasets.length; i++) {
            legendItems += '<div class="legend-item" data-index="' + chart.legend.legendItems[i].datasetIndex + '">'
            const ratingKey = chart.data.datasets[i].title
            const totalActiveStars = ratingMap[ratingKey]

            for (let j = 1; j <= totalStars; j++) {
              if (j <= totalActiveStars) {
                const imgPath = require('@/assets/img/world/loyalty/star_' + ratingKey + '.svg')
                legendItems += '<img class="star" alt="active star" src="' + imgPath + '"/>'
              } else {
                const imgPath = require('@/assets/img/world/loyalty/star.svg')
                legendItems += '<img class="star" alt="inactive star" src="' + imgPath + '"/>'
              }
            }

            legendItems += '</div>'
          }

          // add legend to legend wrapper
          document.querySelector(wrapper + ' .legend').innerHTML = legendItems
          document.querySelector(wrapper + ' .legend').addEventListener('click', (e) => {
            let element = e.target

            if (element.classList.contains('star')) {
              element = e.target.parentNode
            }

            const index = element.dataset.index

            if (index) {
              element.classList.toggle('hidden')
              this.$emit('update', element.classList.contains('hidden') ? index : -1)
              updateDataset(chart, index)
            }
          })
        },
        axes: {
          display: false
        },
        tooltips: {
          enabled: false,
          mode: 'point',
          custom: (tooltipModel) => {
            // Tooltip Element
            var tooltipEl = document.getElementById('chartjs-tooltip-' + this.chartId)

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div')
              tooltipEl.id = 'chartjs-tooltip-' + this.chartId
              tooltipEl.classList.add('chartjs-tooltip')
              tooltipEl.innerHTML = '<div class="tooltip-inner"></div>'
              document.body.appendChild(tooltipEl)
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.classList.toggle('sm')
              return
            }

            tooltipEl.classList.remove('sm')

            // Set Text
            if (tooltipModel.body) {
              const bodyLines = tooltipModel.body.map(body => body.lines)
              let tooltipContent = '<div class="body">'

              bodyLines.forEach(function (body, i) {
                // let colors = tooltipModel.labelColors[i];
                // let style = 'background:' + colors.backgroundColor + ';';
                // let span = '<span style="' + style + '"></span>';

                tooltipContent += '<div>' + body + '</div>'
              })

              tooltipContent += '</div>'

              tooltipEl.innerHTML = tooltipContent
            }

            // get the active (hovered) chart element to calculate the tooltip position
            const dataPoints = tooltipModel.dataPoints
            const index = dataPoints[0].datasetIndex
            const activeChartElements = this._data._chart.active
            let chartElement = null

            for (let i = 0; i < activeChartElements.length; i++) {
              const el = activeChartElements[i]
              if (el._datasetIndex === index) {
                chartElement = el
                break
              }
            }

            if (chartElement != null) {
              const position = this.$refs.canvas.getBoundingClientRect()
              const bar = chartElement._model
              const tooltipPositionX = bar.base + ((bar.x - bar.base) / 2)
              const tooltipPositionY = bar.height

              tooltipEl.style.left = position.left + tooltipPositionX + 'px'
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipPositionY + 'px'
            }
          }
        },
        dataset: {
          barPercentage: 100,
          categoryPercentage: 100
        },
        scales: {
          xAxes: [{
            display: false,
            stacked: true,
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            display: false,
            stacked: true,
            gridLines: {
              display: false
            }
            // barPercentage: 100,
          }]
        }
      }
    }
  },
  methods: {
    renderFinalChart: function () {
      this.renderChart(this.chartData, this.options)
      this.generateLegend()
    }
  }
}

export default {
  props: ['data', 'chartId'],
  components: { Chart },
  data () {
    return {
      disabledIndex: -1,
      total: 0,
      activeIndex: 0,
      ranks: [],
      rankPercentages: [],
      activePercentage: 0
    }
  },
  mounted () {
    this.setPercentage()
    this.$refs.chart.renderFinalChart()
  },
  methods: {
    setPercentage (disabled = -1) {
      this.disabledIndex = disabled
      this.ranks = []
      this.total = 0

      for (const i in this.data.datasets) {
        const dataset = this.data.datasets[i]
        const value = disabled !== i ? dataset.data[0] : 0

        this.ranks.push(value)
        this.total += value

        if (dataset.active) {
          this.activeIndex = i
        }
      }

      let tot = 0

      for (let i = 0; i <= this.activeIndex; i++) {
        tot += this.ranks[i]
      }

      this.activePercentage = ((tot - this.ranks[this.activeIndex]) + (this.ranks[this.activeIndex]) / 2) / this.total * 100
    }
  }
}
</script>
