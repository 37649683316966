<template>
  <div class="world loyalty" v-if="dealer">
    <div class="wrapper-title">
      <div class="container">
        <router-link :to="{name: 'ww.dashboard'}" class="text-wilmsLightBlue font-100 pb-4 block">&larr; {{ $t('Back to overview') }}</router-link>

        <div class="loyalty-date">{{ date }}</div>
        <div class="loyalty-title">{{ $t('Welkom terug,') }} {{ currentUser.profile.first_name }}</div>
        <div v-if="dealer" class="loyalty-chosen-dealer">{{ $t('Gekozen dealer:') + dealer.name }}</div>
      </div>
    </div>

    <div v-if="dealer.loyalty" class="wrapper-menu">
      <div class="container">
        <ul class="loyalty-menu">
          <li class="active">
            <router-link :to="{name: 'ww.loyalty'}">{{ $t('Status') }}</router-link>
          </li>
          <li>
            <router-link data-cy="loyalty-advantages-button" v-if="checkPermissions(['loyalty_advantage.view'])" :to="{name: 'ww.loyalty.advantages'}">{{ $t('Voordelen') }}</router-link>
          </li>
          <li>
            <router-link data-cy="loyalty-upgrades-button" v-if="checkPermissions(['loyalty_upgrade.view'])" :to="{name: 'ww.loyalty.upgrade'}">{{ $t('Upgraden') }}</router-link>
          </li>
        </ul>

        <a class="blueButton btn-sm" download href="/Wilms_Salespresentatie_DEF_NL-gecomprimeerd.pdf">
          <div>
            <img width="16" src="@/assets/img/world/loyalty/download.png" alt="alert">{{ $t('Download sterrenprogramma') }}
          </div>
        </a>
      </div>
    </div>

    <div v-if="dealer.loyalty" class="wrapper-status">
      <div class="container">
        <div class="col-status">
          <div class="status">
            <h3>{{ $t('Jouw status') }}</h3>
            <Stars :stars="dealer.loyalty.stars"/>
          </div>
          <div class="period" v-if="dealer.loyalty.review_start">
            <h3>{{ $t('Reviewperiode') }}</h3>
            <p>
              {{ formatDate(dealer.loyalty.review_start) }} - {{ formatDate(dealer.loyalty.review_end) }}
            </p>
          </div>
        </div>

        <div class="col-upgrade">
          <img width="32" src="@/assets/img/world/loyalty/alert.svg" alt="alert">

          <div v-if="dealer.loyalty.stars === 4">
            <p>{{ $t('Je behaalde de maximum score en bent dus een ware schaduwmeester die steeds opnieuw de hoogste kwaliteit aflevert. Puik werk!') }}</p>
          </div>
          <div v-if="dealer.loyalty.next_stars">
            <p class="mr-2">{{ $t('Upgrade naar') }}</p>
            <Stars :stars="dealer.loyalty.next_stars"></Stars>
            <p v-html="$t('points_to_earn', { 0: dealer.loyalty.points_to_earn || 0 })"></p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="dealer.loyalty" class="wrapper-overview">
      <div class="container">
        <div class="col-title">
          <div>
            <h4>{{ $t('Overzicht') }}</h4>
            <p>{{ $t('Totaalscore') }}: {{ dealer.loyalty.total || 0 }}</p>
          </div>
        </div>

        <div class="col-data col-volume">
          <div class="title">
            <h4>{{ $t('Volume') }}</h4>
            <p>
              {{ dealer.loyalty.volume_total || 0 }}/35
            </p>
          </div>
          <div class="data">
            <h5>{{ $t('Omzet') }}</h5>
            <p>{{ dealer.loyalty.volume_revenue_score || 0 }}/25</p>

            <VerticalBarChart chart-id="revenue" :data="chartDataRevenue()"></VerticalBarChart>
          </div>
          <div class="data">
            <h5>{{ $t('Duurzaamheid & Anciënniteit') }}</h5>
            <p>{{ dealer.loyalty.volume_seniority_score || 0 }}/5</p>
          </div>
          <div class="data">
            <h5>{{ $t('Potentieel & groei') }}</h5>
            <p>{{ dealer.loyalty.volume_growth_score || 0 }}/5</p>
          </div>
        </div>

        <div class="col-data">
          <div class="title">
            <h4>{{ $t('Sales & Marketing') }}</h4>
            <p>
              {{ dealer.loyalty.sales_marketing_total || 0 }}/25
            </p>
          </div>
          <div class="data">
            <h5>{{ $t('Opvolging leads') }}</h5>
            <p>{{ dealer.loyalty.sales_marketing_followup_leads_score || 0 }}/10</p>

            <CircleChart chart-id="leads" :data="chartDataLeads()"></CircleChart>
          </div>
          <div class="data">
            <h5>{{ $t('Marketing & zichtbaarheid') }}</h5>
            <p>{{ dealer.loyalty.sales_marketing_visibility_score || 0 }}/15</p>

            <HorizontalBarChart chart-id="visibility" :data="chartDataVisibility()"></HorizontalBarChart>
          </div>
        </div>

        <div class="col-data col-quality">
          <div class="title">
            <h4>{{ $t('Kwaliteit') }}</h4>
            <p>
              {{ dealer.loyalty.quality_total || 0 }}/40
            </p>
          </div>
          <div class="data-2-col">
            <div>
              <div class="data">
                <h5>{{ $t('Betaalgedrag') }}</h5>
                <p>{{ dealer.loyalty.quality_payment_behavior_score || 0 }}/5</p>
              </div>
              <div class="data">
                <h5>{{ $t('Kwaliteit & training personeel') }}</h5>
                <p>{{ dealer.loyalty.quality_training_staff_score || 0 }}/5</p>
              </div>
            </div>
            <div>
              <div class="data">
                <h5>{{ $t('Gevolgde producten') }}</h5>
                <p>{{ dealer.loyalty.quality_followed_products_score || 0 }}/20</p>
              </div>
              <div class="data">
                <h5>{{ $t('Bestelwijze') }}</h5>
                <p>{{ dealer.loyalty.quality_payment_method_score || 0 }}/10</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-title">
          <div><h4>{{ $t('Benchmark') }}</h4></div>
        </div>
        <div class="col-data col-benchmark chart-app">
          <div class="title">
            <h4>{{ $t('Hoe presteren andere dealers') }}</h4>
          </div>

          <div class="benchmark-chart">
            <h4>{{ $t('Status') }}</h4>

            <HorizontalStackedBarChart v-if="benchmarkData.length > 0" chart-id="benchmark" :data="chartDataBenchmark()"></HorizontalStackedBarChart>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!dealer.loyalty" class="no-data mt-20">
      <div class="container">
        <h2>{{ $t('Geen loyalty gegevens') }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { ApiService } from '@/services/admin/api.service'
import Stars from '@/components/world/Stars'
import { DateTime } from 'luxon'
import VerticalBarChart from '@/components/world/VerticalBarChart'
import HorizontalBarChart from '@/components/world/HorizontalBarChart'
import CircleChart from '@/components/world/CircleChart'
import HorizontalStackedBarChart from '@/components/world/HorizontalStackedBarChart'

export default {
  components: {
    HorizontalStackedBarChart,
    CircleChart,
    HorizontalBarChart,
    VerticalBarChart,
    Stars
  },
  data () {
    return {
      benchmarkData: [],
      total: 0,
      loading: false
    }
  },
  async mounted () {
    await this.setDealer()
    this.benchmarkData = (await ApiService.fetchDealersBenchmark()).data

    if (!this.hasLoyaltyAccess) {
      await this.$router.push({ name: 'ww.dashboard' })
    }
  },
  methods: {
    formatDate (date) {
      return DateTime.fromISO(date).setLocale(this.activeLocale).toFormat('dd/MM/yyyy')
    },
    chartDataRevenue () {
      const color = '#0F1D61'
      const chartData = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: []
          }
        ]
      }

      for (let i = 0; i < this.dealer.loyalty.volume_revenue.length; i++) {
        const revenue = this.dealer.loyalty.volume_revenue[i]
        const pos = this.dealer.loyalty.volume_revenue.length - 1 - i
        chartData.labels[pos] = revenue.year
        chartData.datasets[0].data[pos] = revenue.amount
        chartData.datasets[0].backgroundColor[pos] = color
      }

      return chartData
    },
    chartDataBenchmark () {
      const chartData = {
        type: 'horizontalBar',
        labels: ['Status'],
        datasets: [
          {
            title: 'D',
            label: '',
            xLabel: 'test',
            data: [],
            backgroundColor: '#F7CE80',
            borderColor: '#FFFFFF',
            active: false
            // borderWidth: 1,
          },
          {
            title: 'C',
            label: '',
            data: [],
            backgroundColor: '#79B0EA',
            borderColor: '#FFFFFF',
            active: false
            // borderWidth: 1,
          },
          {
            title: 'B',
            label: '',
            data: [],
            backgroundColor: '#93B279',
            borderColor: '#FFFFFF',
            active: false
            // borderWidth: 1,
          },
          {
            title: 'A',
            label: '',
            data: [],
            backgroundColor: '#0F1D61',
            borderColor: '#FFFFFF',
            active: false
            // borderWidth: 1,
          }
        ]
      }

      const ratings = this.benchmarkData
      const copy = ratings.slice()
      copy.sort((a, b) => a.rating > b.rating ? 1 : -1)

      copy.forEach((rating, i) => {
        chartData.datasets[i].data = [rating.total]
      })

      chartData.datasets[this.dealer.loyalty.stars - 1].active = true
      return chartData
    },
    chartDataVisibility () {
      const data = this.dealer.loyalty.sales_marketing_visibility_score
      const total = 15

      const chartData = {
        datasets: [
          {
            data: [],
            backgroundColor: ['#0F1D61'],
            borderWidth: 0
          },
          {
            data: [],
            backgroundColor: ['#0F1D61'],
            borderWidth: 0
          }
        ]
      }

      let colorActive = '#93B279'
      let colorInactive = '#f2f6ef'

      if (data < 7) {
        colorActive = '#F78080'
        colorInactive = '#fde7e7'
      } else if (data < 11) {
        colorActive = '#f7ce80'
        colorInactive = '#fdf6e7'
      }

      chartData.datasets[0].data[0] = data
      chartData.datasets[0].backgroundColor[0] = colorActive

      chartData.datasets[1].data[0] = total - data
      chartData.datasets[1].backgroundColor[0] = colorInactive

      return chartData
    },
    chartDataLeads () {
      const chartData = {
        labels: ['Win', 'Loss'],
        datasets: [
          {
            data: [],
            backgroundColor: [],
            borderWidth: 0
          }
        ]
      }

      const total = 10
      const data = this.dealer.loyalty.sales_marketing_followup_leads_score

      let colorActive = '#93B279'
      let colorInactive = '#f2f6ef'

      if (data < 5) {
        colorActive = '#F78080'
        colorInactive = '#fde7e7'
      } else if (data < 8) {
        colorActive = '#f7ce80'
        colorInactive = '#fdf6e7'
      }

      chartData.datasets[0].data[0] = data
      chartData.datasets[0].backgroundColor[0] = colorActive

      chartData.datasets[0].data[1] = total - data
      chartData.datasets[0].backgroundColor[1] = colorInactive

      return chartData
    },
    chartDataDna () {
      const data = this.dealer.loyalty.quality_dna_match
      const total = 5

      const chartData = {
        datasets: [
          {
            data: [],
            backgroundColor: ['#0F1D61'],
            borderWidth: 0
          },
          {
            data: [],
            backgroundColor: ['#0F1D61'],
            borderWidth: 0
          }
        ]
      }

      let colorActive = '#93B279'
      let colorInactive = '#f2f6ef'

      if (data * 2 < 5) {
        colorActive = '#F78080'
        colorInactive = '#fde7e7'
      } else if (data * 2 < 8) {
        colorActive = '#f7ce80'
        colorInactive = '#fdf6e7'
      }

      chartData.datasets[0].data[0] = data
      chartData.datasets[0].backgroundColor[0] = colorActive

      chartData.datasets[1].data[0] = total - data
      chartData.datasets[1].backgroundColor[0] = colorInactive

      return chartData
    }
  },
  computed: {
    date () {
      const date = moment().locale(this.activeLocale).format('dddd, DD MMMM YYYY')
      return date.charAt(0).toUpperCase() + date.slice(1)
    }
  }
}
</script>
