<template>
  <div :class="'chart-' + chartId" class="chart-app">
    <chart ref="chart" :chart-id="chartId" :chart-data="data"></chart>
  </div>
</template>

<script>
import { mixins, HorizontalBar } from 'vue-chartjs'

const { reactiveProp } = mixins

// function to update the chart when clicking the legend items
// function updateDataset (chart, index) {
//   var meta = chart.getDatasetMeta(index)
//   meta.hidden = meta.hidden === null ? !chart.data.datasets[index].hidden : null
//   chart.update()
// }

const Chart = {
  extends: HorizontalBar,
  mixins: [reactiveProp],
  props: ['chartData', 'chartId'],
  data: function () {
    return {
      responsive: true,
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: false,
          mode: 'point',
          custom: (tooltipModel) => {
            // Tooltip Element
            var tooltipEl = document.getElementById('chartjs-tooltip-' + this.chartId)

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div')
              tooltipEl.id = 'chartjs-tooltip-' + this.chartId
              tooltipEl.classList.add('chartjs-tooltip')
              tooltipEl.innerHTML = '<div class="tooltip-inner"></div>'
              document.body.appendChild(tooltipEl)
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.classList.toggle('sm')
              return
            }

            tooltipEl.classList.remove('sm')

            // Set Text
            if (tooltipModel.body) {
              const bodyLines = tooltipModel.body.map(body => body.lines)
              let tooltipContent = '<div class="body">'

              bodyLines.forEach(function (body, i) {
                // let colors = tooltipModel.labelColors[i];
                // let style = 'background:' + colors.backgroundColor + ';';
                // let span = '<span style="' + style + '"></span>';

                tooltipContent += '<div>' + body + '</div>'
              })

              tooltipContent += '</div>'

              tooltipEl.innerHTML = tooltipContent
            }

            // get the active (hovered) chart element to calculate the tooltip position
            const dataPoints = tooltipModel.dataPoints
            const index = dataPoints[0].datasetIndex
            const activeChartElements = this._data._chart.active
            let chartElement = null

            for (let i = 0; i < activeChartElements.length; i++) {
              const el = activeChartElements[i]
              if (el._datasetIndex === index) {
                chartElement = el
                break
              }
            }

            if (chartElement != null) {
              const position = this.$refs.canvas.getBoundingClientRect()
              const bar = chartElement._model
              const tooltipPositionX = bar.base + ((bar.x - bar.base) / 2)
              const tooltipPositionY = bar.height

              tooltipEl.style.left = position.left + tooltipPositionX + 'px'
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipPositionY + 'px'
            }
          }
        },
        scales: {
          xAxes: [{
            display: false,
            stacked: true,
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            display: false,
            stacked: true,
            gridLines: {
              display: false
            },
            // barPercentage: 100,
            ticks: {
              beginAtZero: true,
              max: 10
            }
          }]
        }
      }
    }
  },
  methods: {
    renderFinalChart: function () {
      this.renderChart(this.chartData, this.options)
      this.generateLegend()

      if (document.querySelector('body').classList.contains('pdf')) {
        const datasets = this._data._chart.tooltip._data.datasets
        const that = this

        datasets.forEach(function (obj, i) {
          const chartElement = that._data._chart.getDatasetMeta(i).data[0]._model
          const tooltipEl = document.createElement('div')
          tooltipEl.id = 'chartjs-tooltip-' + that.chartId + '-' + i
          tooltipEl.classList.add('chartjs-tooltip')
          document.body.appendChild(tooltipEl)

          tooltipEl.innerHTML = '<div class="body"><div><span style="background: ' + obj.backgroundColor + ';"></span>' + obj.data[0] + '</div></div>'

          if (chartElement != null) {
            const position = that.$refs.canvas.getBoundingClientRect()
            const tooltipPositionX = chartElement.x
            const tooltipPositionY = 120

            tooltipEl.style.left = position.left + tooltipPositionX + 'px'
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipPositionY + 'px'
          }
        })
      }
    }
  }
}

export default {
  props: ['data', 'chartId'],
  components: { Chart },
  mounted () {
    this.$refs.chart.renderFinalChart()
  }
}
</script>
